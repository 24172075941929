import React, { useState, useEffect, useRef } from 'react';
import { Menu } from 'primereact/menu';
import { Image } from 'primereact/image';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Toast } from 'primereact/toast';
import ReactCountryFlag from "react-country-flag"
import logo01 from 'images/logo3.png';
import { MenuData, LevelData, DefSystem } from '../../components/contentsParts/DbData';


const Header = () =>{
    const { t, i18n } = useTranslation();
    // const [ langStrage, setLangStrage ] = useState('');
    const toast = useRef(null);
    // const [ userLevel, setUserLevel ] = useState(1000);
    const [ publicLanguageSelect, setPublicLanguageSelect ] = useState(0);
    const [ memberLanguageSelect, setMemberLanguageSelect ] = useState(0);
    const [ token, setToken ] = useState('');
    const [ nickname, setNickname ] = useState('');
    const [ language, setLanguage ] = useState('')
    const [ menuData, setMenuData] = useState([]);
    const [ editMenuData, setEditMenuData] = useState([]);
    const [smMenuVisible, setSmMenuVisible] = useState(false);
    const [ctlMenuVisible, setCtlMenuVisible] = useState(false);
    const navigate = useNavigate();

    const langeChange = (lng) => {
        const expires = Date.now() + (30 * 24 * 60 * 60 * 1000); // 30日後のミリ秒
        // localStorage.setItem('Lang', lng, { expires });
        const arrValue = {"value":lng, expires}
        localStorage.setItem('Lang', JSON.stringify(arrValue) );
        setTimeout(() => {
            window.location.reload();
        },1000)
    }
    const LogoFunction = () =>{
        return(
            <>
                <a href="/" >
                    <Image 
                        alt="logo" 
                        src={logo01} 
                        imageClassName="px-2 py-0"
                        className='max-h-[40px] w-40 bottom-6'
                    />
                </a>
            </>
        )
    }

    const MenuFunction = () =>{
        return (
                    <div className="h-[60px] content-end my-0 py-0 ">
                        <Menu 
                            model={menuData} 
                            className="!w-auto !bg-inherit !border-none !mb-2"
                            pt={{
                                root: ' ',
                                menu: '!m-0 !p-0 list-none outline-none flex flex-wrap ',
                                content: 'text-pink-400  hover:!text-pink-700 hover:font-bold hover:!bg-inherit !py-0 mb-1 hover:mb-0 text-xs',
                                menuitem: 'hover:border-b-4 hover:!border-pink-800/80 hover:!text-pink-700 hover:!border-double hover:!bg-inherit  mb-1 hover:mb-0',
                                label:'hover:!text-pink-700 text-pink-700',
                                icon: 'hover:!text-pink-700 text-xs pi-fw text-pink-700',
                                action:'!py-0 !px-2 hover:!no-underline'
                            }}
                        />
                    </div>
                );
    }
    const SmMenuFunction = () =>{
        const visibleOpt = () =>{
            return smMenuVisible? 'flex' : 'hidden';
        }
        return (
                <div className={visibleOpt()+` items-center !w-fit !z-50 absolute top-[70px]`}>
                    <Menu 
                        model={menuData} 
                        pt={{
                            root: 'md:hidden !bg-gray-100 !shadow w-auto',
                            content: 'text-gray-600 hover:text-blue-900 hover:font-bold',
                            menuitem: 'hover:!border-s-4 border-blue-900',
                            icon: 'text-gray-600 mr-1',
                        }}
                    />
                </div>
                );
    }
    const CtlMenuFunction = () =>{
        const visibleOpt = () =>{
            return ctlMenuVisible ? 'flex' : 'hidden';
        }
        return (
                <div className={visibleOpt()+` content-end !w-fit !z-50 absolute right-0 top-[70px]`}>
                    <div>
                        <Menu 
                            model={editMenuData} 
                            pt={{
                                root: '!bg-gray-100 !shadow w-auto',
                                content: 'text-gray-600 hover:text-blue-900 hover:font-bold',
                                menuitem: 'hover:!border-e-4 border-blue-900',
                                icon: 'text-gray-600 mr-1',
                            }}
                        />
                    </div>
                </div>
                );
    }
    const smMenuhandleClick = () => {
        if(ctlMenuVisible) setCtlMenuVisible(!ctlMenuVisible);
        setSmMenuVisible(!smMenuVisible); // 状態をトグル
    };
    const ctlMenuhandleClick = () => {
        if(smMenuVisible)setSmMenuVisible(!smMenuVisible);
        setCtlMenuVisible(!ctlMenuVisible); // 状態をトグル
    };
    const CtlAdminFunction = () => {
        if(!token){
            return(
                // provisionalRegistrasion

                <div className='object-contain menuRight'>
                <Button
                    className='preregiBt w-full'
                    icon='pi pi-pen-to-square'
                    label={t('provisionalRegistrasion')}
                    iconPos="left"
                    unstyled='true'
                    // pt={{
                    //     root:'hover:bg-gray-600/30 p-2 hover:rounded w-full',
                    //     icon:'text-xs',
                    //     label:'text-xs'
                    // }}
                    onClick={() => {
                        navigate('/');
                    }}
                >
                </Button>
                <Button
                    className='loginBt w-full'
                    icon='pi pi-face-smile'
                    label={t('login')}
                    iconPos="left"
                    unstyled='true'
                    // pt={{
                    //     root:'hover:bg-gray-600/30 p-2 hover:rounded w-full',
                    //     icon:'text-xs',
                    //     label:'text-xs'
                    // }}
                    onClick={() => {
                        navigate('/login');
                    }}
                        
                >
                </Button>
                </div>
            )
        }else{
            return(
                // <div className='flex flex-col justify-center h-[60px] pt-2'>
                //     <div className='text-xs w-auto contents-center items-center mx-auto'>
                //         <p className="bg-stone-50 py-1 my-1 px-2  border border-gray-300 rounded">
                //             {t('controlMenu')}
                //         </p>
                //     </div>
                    <div className='object-contain  menuRight'>
                        <Button
                            className='!bg-inherit border-none !text-gray-800 w-full'
                            icon='pi pi-user'
                            label={nickname}
                            size='large'
                            iconPos="right"
                            pt={{
                                root:'hover:bg-gray-600/30 p-2 hover:rounded w-full',
                                icon:'text-xs',
                                label:'text-xs'
                            }}
                            onClick={ctlMenuhandleClick}
                        >
                        </Button>
                    </div>
                // </div>
            )
        }
    }
    const CtlSmartMenuFunction = () => {
        return(
            <>
                <Button
                    className='!bg-inherit border-none !text-gray-800 '
                    icon='pi pi-bars'
                    pt={{
                        root:'hover:bg-gray-600/30 p-2 rounded',
                        label:'hidden',
                        icon:{
                            className:'text-xl py-2'
                        }
                    }}
                    onClick={smMenuhandleClick}
                >
                </Button>
            </>
        )
    }
    const CtlLangMenuFunction = () => {
        const level = parseInt(sessionStorage.getItem('level'),10)
        if((!level && publicLanguageSelect !== '1') || (level && memberLanguageSelect !=='1') ){
            return null
        }else{
            return(
                // <div className='flex flex-col justify-center h-[60px] pt-2'>
                //     <div className='text-xs w-auto contents-center items-center mx-auto'>
                //         <p className="bg-stone-50 py-1 my-1 px-2 border border-gray-300 rounded">
                //             {t('language')}
                //         </p>
                //     </div>
                    <div className='border border-gray-400 rounded flex flex-row h-[34px]'>
                        <Button 
                            className='!bg-inherit border-none !text-gray-800 text-xs w-auto !p-2 !m-0'
                            onClick={() => langeChange('en')}
                            pt={{
                                label:{
                                    className:'text-xs !p-0 !m-0'
                                }
                            }}
                    >
                            <ReactCountryFlag countryCode="US" svg  className='text-md min-w-4'/>
                        </Button>
                        <Button 
                            className='!bg-inherit border-none !text-gray-800 text-xs w-auto !p-2 !m-0'
                            onClick={() => langeChange('ja')}
                            pt={{
                                label:{
                                    className:'text-xs !p-0 !m-0'
                                }
                            }}
                        >
                            <ReactCountryFlag countryCode="JP" svg  className='text-md min-w-4'/>
                        </Button>
                        <Button 
                            className='!bg-inherit border-none !text-gray-800 text-xs w-auto !p-2 !m-0'
                            onClick={() => langeChange('zhCn')}
                            pt={{
                                label:{
                                    className:'text-xs !p-0 !m-0'
                                }
                            }}
                        >
                            <ReactCountryFlag countryCode="CN" svg  className='text-md min-w-4'/>
                        </Button>
                    </div>
                // </div>
            )
        }
    }
    useEffect(() => {
        const token = sessionStorage.getItem('token');

        let defLang = '';
        // let pubLang = '';
        // let memLang = '';
        if(token){
            setToken(token);
            setNickname(sessionStorage.getItem('nickname'));
        }
        const getValuefunction = async(name,val) =>{
            const lStrage = localStorage.getItem(name);
            const data = JSON.parse(lStrage);
            const currentTime = new Date().getTime();
            if(!lStrage || data.expires < currentTime){
                localStorage.removeItem(name);
                const resData =  await DefSystem(name);
                if(name === 'defaultLanguage')defLang = resData.value;
                if(name === 'publicLanguageSelect'){
                    // pubLang = resData.value;
                    setPublicLanguageSelect(resData.value);
                }  
                if(name === 'memberLanguageSelect'){
                    // memLang = resData.value;
                    setMemberLanguageSelect(resData.value);
                }
            }else{
                if(name === 'defaultLanguage')defLang = data.value;
                if(name === 'publicLanguageSelect'){
                    // pubLang = data.value;
                    setPublicLanguageSelect(data.value);
                }
                if(name === 'memberLanguageSelect'){
                    // memLang = data.value;
                    setMemberLanguageSelect(data.value);
                }
            }
        }
        getValuefunction('defaultLanguage');
        getValuefunction('publicLanguageSelect');
        getValuefunction('memberLanguageSelect');

        const langLoadFunction =() => {
            const val = localStorage.getItem('Lang');
            if(val){
                const data = JSON.parse(val);
                // setLangStrage(data.value);
                setLanguage(data.value);
                i18n.changeLanguage(data.value);
            }else{
                setLanguage(defLang);
                const expires = Date.now() + (30 * 24 * 60 * 60 * 1000); // 30日後のミリ秒
                if(defLang){
                    const arrValue = {"value":defLang, expires}
                    localStorage.setItem('Lang', JSON.stringify(arrValue) );
                }
                i18n.changeLanguage(defLang);
                setTimeout(() => {
                    langLoadFunction();
                },1000);
            }
        }
        langLoadFunction();
    },[i18n])

    useEffect(() => {
        const getMenuFunction = async () => {
            const getData = await MenuData(1,language);
            if(getData){
                setMenuData(getData);
                try {
                    setMenuData(JSON.parse(getData));
                } catch (e) {
                }
            }
            const userLevel = parseInt(sessionStorage.getItem('level'),10);
            if(userLevel > 0){
                const getData100 = await MenuData(userLevel,language);
                if(getData100){
                    setEditMenuData(getData100);
                    try {
                        setEditMenuData(JSON.parse(getData100));
                    } catch (e) {
                    }
                }
            }
        }
        const getLevelFunction = async () => {
            // console.log("In level");
            await LevelData(language);
            // console.log("level"+levelData);
        }
        const checkTokenExpiry = () => {
            const token = sessionStorage.getItem('token');
            const issuedAt = sessionStorage.getItem('tokenIssuedAt');
            
            if (token && issuedAt) {
                const issuedDate = new Date(issuedAt);
                const now = new Date();
                
                const diff = now - issuedDate; // 差分をミリ秒で取得
                const oneDay = 24 * 60 * 60 * 1000; // 1日のミリ秒数
                
                if (diff > oneDay) {
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('tokenIssuedAt');
                    sessionStorage.removeItem('email');
                    sessionStorage.removeItem('level');
                    sessionStorage.removeItem('nickname');
                    toast.current.show({ severity: 'warn', summary: 'Warning', detail: t('loginHasExpired'), life: 3000 });
                    setTimeout(() => {
                        window.location.reload();
                    },3000);
                }
            }
        };
        if (language) {
            checkTokenExpiry();
            getLevelFunction();
            getMenuFunction();
        }
    },[language,token,t]);

    return(
        <div className="fixed top-0 left-0 right-0 absolute z-50 !fixed bg-rose-200 w-100 !shadow prose bg-rose-200 w-100"
                // style={{
                    // backgroundImage:'linear-gradient(9deg, rgba(220, 248, 183, 0), rgba(255, 255, 255, 0) 34%, rgb(112 220 254 / 60%) 72%)'
                // }}
        >
            <div className="w-full flex flex-row w-100 justify-between max-w-screen-xl mx-auto relative" 
                >
                <div className="flex flex-row h-[70px] ">
                    {/* Logo */}
                    <div>
                        <LogoFunction />
                    </div>
                    {/* Main menu */}
                    <div className="hidden items-center content-center text-pink-800 md:flex !h-16 !max-h-16 !min-h-16">
                        <MenuFunction />
                    </div>
                    {/* Right control menu */}
                    <div className="visible items-center content-center py-2 sm:pt-0 md:hidden">
                        {/* Smart phone cntrol */}
                        <CtlSmartMenuFunction />
                    </div>
                </div>
                <Toast ref={toast} />
                <div className="flex flex-row justify-end pr-2">
                    <div className='flex flex-col'>
                        {/* Lang menu */}
                        <CtlLangMenuFunction />
                        {/* Admin cntrol */}
                        <CtlAdminFunction />
                    </div>
                </div>
            {/* Header menu at under style  */}
            <SmMenuFunction />
            <CtlMenuFunction />
            </div>
        </div>
    )
}
export default Header